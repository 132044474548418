// Here you can add other styles
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// css for patients form

#button-collapsible {
  background-color: #54688a;
  color: white;
  cursor: pointer;
  text-align: left;
}

#button-collapsible:hover {
  background-color: #3c4b64;
}

#nested-button-collapsible,
#overview-collapsible-content {
  background-color: #2eb85c1a;
  color: #505D73;

  text-align: left;
}

#button-collapsible:hover {
  background-color: #3c4b64;
  cursor: pointer;
  opacity: 0.2;
}

.custom-input:focus {
  outline: none;
  box-shadow: none;
}

.btn-icon:hover {
  cursor: pointer;
}

.markdown img {
  width: 100%;
  margin: 5px;
  height: auto;
}

//css for patients list

.badge-purple {
  color: #fff;
  background-color: purple;
}

.badge-orange {
  color: #4f5d73;
  background-color: #FF8C00;
}

.badge-pink {
  background-color: pink;
  color: #4f5d73;
}

.badge-silverSage {
  background-color: #938B78;
  color: #fff;
}

.badge-sudanBrown {
  background-color: #AC6C29;
  color: #fff;
}

.badge-neptuneBlue {
  background-color: #2E5D9D;
  color: #fff;
}

.badge-hotPink {
  background-color: #FF69B4;
  color: #fff;
}

.badge-darkGreen {
  background-color: #295c37;
  color: #fff;
}

.badge-grey {
  background-color: #747474;
  color: #fff;
}

.badge-light-orange {
  background-color: #FFF2CD;
  color: #664D03;
}

.badge-light-green {
  background-color: #cdffd1;
  color: #036615;
}

.badge-light-red {
  background-color: #ffcdcd;
  color: #661203;
}

.extra-light-gray {
  background-color: #ebedef20;
}

.label-checkbox:hover {
  cursor: pointer;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: #321fdb !important;
  border: 1px solid #321fdb !important;
  font-weight: bold;
}

.nav-tabs .nav-link {
  color: #768192;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid;
  border-color: #c4c9d0;
}

.nav-tabs {
  border: 0px;
}

.modal-header {
  background-color: #3c4b64;
  color: white;

  .close {
    color: white;
    opacity: 0.8;
  }
}

.cursor-pointer:hover {
  cursor: pointer;
}

.small-card-font {
  //styleName: h7;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.09px;
  text-align: left;
  color: #9DA5B1;
}

.no-caret::after {
  display: none;
  /* Hide the default dropdown caret */
}